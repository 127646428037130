import React from "react";

import { AppRouter } from "./routers/AppRouter";

export const AtlantisApp = () => {
  return (
    <div>
      <AppRouter />
    </div>
  );
};
